import http from "../http-common";

class PromocodeDataService {

  getAll() {
    return http.get("/promotionalcode/all")
  }

  get(id) {
    return http.get("/promotionalcode/"+id)
  }

  newPromocode(
    active,
    code,
    discount,
    expire_date,
    max_uses
  ) {
    return http.post("/promotionalcode/create", {
      "active": active,
      "code": code,
      "discount": discount,
      "expire_date": expire_date,
      "max_uses": max_uses
    })
  }

  updatePromocode(
    id,
    active,
    code,
    discount,
    expire_date,
    max_uses
  ) {
    return http.post("/promotionalcode/update", {
      "id": id,
      "active": active,
      "code": code,
      "discount": discount,
      "expire_date": expire_date,
      "max_uses": max_uses
    })
  }
}

export default new PromocodeDataService();
